<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card class="mb-4">
      <v-card-title>
        <div>{{ text }} Promo Code</div>
      </v-card-title>
      <v-card-text class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              name="code"
              label="Redemption Code"
              @change="onChangeCode()"
              clearable
              v-model="data.code"
              v-on:keypress="isPromocode($event)"
              :error-messages="errors"
              :rules="[v => !!v || 'Redemption Code is required']"
              placeholder="e.g. Name of Code"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              name="totalRedemptions"
              label="Number of Redemption"
              clearable
              v-on:keypress="isNumber($event)"
              v-model="data.numberOfRedemptions"
              :rules="[v => !!v || 'Number of Redemption is required']"
              placeholder="e.g. Max uses of promocode"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12">
            <v-autocomplete
              v-model="data.organiser"
              clearable
              :items="organisers"
              :filter="customFilter"
              @change="getEvents()"
              item-text="name"
              item-value="_id"
              label="Promoter"
              :rules="[v => !!v || 'Promoter is required']"
              dense
              outlined
            >
            <template v-slot:selection="data">
            <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
            </template>
            <template v-slot:item="data">
              <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
            </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="position-relative">
          <v-col cols="12" sm="6">
            <v-text-field
              class="mr-0 mr-sm-2"
              outlined
              dense
              type="number"
              clearable
              name="price"
              label="Discount Amount"
              :rules="[discountRequiredRule, v => priceFormat(data.discountedPrice) || 'Only two digits after decimal points allowed.']"
              v-model="data.discountedPrice"              
            />
          </v-col>

          <v-col cols="12" class="hidden-sm-and-up text-center">
            <span class="primary--text text-bold">Or</span>
          </v-col>

          <span class="primary--text text-bold absolute-center-x hidden-xs-only">Or</span>

          <v-col cols="12" sm="6">
            <v-text-field
              class="ml-o ml-sm-2"
              outlined
              dense
              clearable
              type="number"
              name="amount"
              label="Discount in %"
              v-model="data.discountedPercentage"
              :rules="[amountRule, v => pricePercentageFormat(data.discountedPercentage) || 'Only two digits after decimal points allowed.']"
              
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="3">
            <v-menu
              v-model="startdatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <v-text-field
                    outlined
                    clearable
                    v-model="data.startsAt"
                    dense
                    label="Starts Date"
                    append-icon="mdi-calendar"
                    :rules="[v => !!v || 'Starts Date is required', checkStartDate]"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </div>
              </template>
              <v-date-picker v-model="data.startsAt" @input="startdatePicker = false" />
            </v-menu>
          </v-col>
          <v-col cols="12" sm="3">
            <v-menu
              ref="menu"
              v-model="datepicker1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  clearable
                  v-model="data.starttime"
                  dense
                  label="Starts Time"
                  :rules="[v => !!v || 'Starts Time is required']"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="datepicker1"
                format="24hr"
                v-model="data.starttime"
                full-width
                @click:minute="(datepicker1 = false), $refs.menu.save(data.starttime)"
              ></v-time-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="3">
            <v-menu
              v-model="enddatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <v-text-field
                    outlined
                    clearable
                    v-model="data.expiresAt"
                    dense
                    label="Ends Date"
                    :rules="[v => !!v || 'Ends Date is required']"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </div>
              </template>
              <v-date-picker :min="data.startsAt" v-model="data.expiresAt" @input="enddatePicker = false" />
            </v-menu>
          </v-col>
          <v-col cols="12" sm="3">
            <v-menu
              ref="menu"
              v-model="datepicker2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  clearable
                  v-model="data.endtime"
                  dense
                  label="Ends Time"
                  :rules="[v => !!v || 'Ends Time is required']"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="datepicker2"
                format="24hr"
                v-model="data.endtime"
                full-width
                @click:minute="(datepicker2 = false), $refs.menu.save(data.endtime)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12">
            <v-autocomplete
              v-model="data.events"
              multiple
              :items="events"
              clearable
              :item-text="'title'"
              :item-value="'_id'"
              label="Events"
              :rules="[v => !!v || 'Events is required']"
              dense
              outlined
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-select
              v-model="data.status"
              :items="statuses"
              :item-text="'text'"
              :item-value="'value'"
              label="Status"
              dense
              clearable
              :rules="[v => !!v || 'Status is required']"
              @click:clear="clear('status')"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              class="ml-o ml-sm-2"
              outlined
              dense
              clearable
              name="minimum"
              label="Minimum Quantity"
              :rules="[v => !!v || 'Minimum Quantity is required']"
              v-on:keypress="isNumber($event)"
              v-model="data.minimumQuantityCanBePurchased"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              class="ml-o ml-sm-2"
              outlined
              dense
              clearable
              name="maximum"
              label="Maximum Quantity"
              :rules="[v => !!v || 'Maximum Quantity is required', quantityRule]"
              v-on:keypress="isNumber($event)"
              v-model="data.maximumQuantityCanBePurchased"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined color="primary" @click="discard()">Cancel</v-btn>
        <v-btn width="100" color="primary" @click="save()" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import moment from 'moment';
import { PromocodeService, PromoterEventService, PromoterService } from '@services';
export default {
  data() {
    return {
      valid: false,
      loading: false,
      text: 'Add New',
      startdatePicker: false,
      datepicker1: false,
      enddatePicker: false,
      datepicker2: false,
      errors: [],
      statuses: [
        { text: 'Active', value: 'active' },
        { text: 'Inactive', value: 'inactive' },
        { text: 'Draft', value: 'draft' },
      ],
      data: {
        events: '',
        code: '',
        discountedPercentage: '',
        discountedPrice: '',
        expiresAt: '',
        totalRedemptions: '',
        startsAt: '',
        status: 'active',
        endtime: '',
        starttime: '',
      },
      events: [],
      organisers: [],
    };
  },
  created: async function() {
    if (this.$route.params.id) {
      this.text = 'Edit';
      this.getVenueById(this.$route.params.id);
    }
    const organiserData = await PromoterService.get({ limit: 'all' });
    
    this.organisers = organiserData.organisers;
  },
  computed: {
    amountRule() {
      return (
        !(this.data.discountedPrice && this.data.discountedPercentage) ||
        'You can not enter discount amount or discount percentage together'
      );
    },
    quantityRule() {
      return (
        !(
          this.data.maximumQuantityCanBePurchased &&
          this.data.minimumQuantityCanBePurchased &&
          Number(this.data.minimumQuantityCanBePurchased) > Number(this.data.maximumQuantityCanBePurchased)
        ) || 'Maximum Quantity must be greater than Minimum Quantity'
      );
    },
    discountRequiredRule() {
      return !(!this.data.discountedPrice && !this.data.discountedPercentage) || 'Discount Amount is required';
    },
  },
  methods: {
    async getEvents() {
      if (this.data.organiser) {
        const eventData = await PromoterEventService.getAll({ organiser: this.data.organiser });
        this.events = eventData;
      }
    },
    customFilter (item, queryText) {
      const textOne = `${item.name.toLowerCase()} - ${item.type.toLowerCase()}`
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 
    },
    checkStartDate() {
      if (this.data.startsAt && this.data.expiresAt) {
        if (moment(this.data.expiresAt).isBefore(moment(this.data.startsAt))) {
          return 'Start date must be before ends date';
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    async getVenueById(id) {
      this.data = await PromocodeService.getById(id);
      this.getEvents();
      this.splitDateAndTime();
    },
    splitDateAndTime() {
      if (this.data.startsAt && this.data.expiresAt) {
        this.data.starttime = moment(this.data.startsAt).format('HH:mm');
        this.data.endtime = moment(this.data.expiresAt).format('HH:mm');
        this.data.startsAt = moment(this.data.startsAt).format('YYYY-MM-DD');
        this.data.expiresAt = moment(this.data.expiresAt).format('YYYY-MM-DD');
      }
    },
    joinDateAndTime() {
      if (this.data.startsAt && this.data.expiresAt) { 
        this.data.startsAt = moment(this.data.startsAt + ' ' + this.data.starttime);
        this.data.expiresAt = moment(this.data.expiresAt + ' ' + this.data.endtime);
      }
    },
    async onChangeCode() {
      this.errors = [];
      const response = await PromocodeService.checkDuplicate(this.data.code);
      if (response) {
        this.errors = ['Redemption code already exists'];
      }
    },
    async save() {
      if (this.$refs.form.validate() && !this.errors.length) {
        this.joinDateAndTime();
        this.$confirm({
          title: `Are you sure you want to ${this.data._id ? 'update' : 'create'} this promocode?`,
          resolve: async () => {
            this.loading = true;
            this.data.code = this.data.code.toLocaleUpperCase();
            if (this.data._id) {
              const data = await PromocodeService.update(this.data);
              if (data) {
                this.$next({ name: 'admin.promo-codes' });
              } else {
                this.splitDateAndTime();
              }
            } else {
              const data = await PromocodeService.save(this.data);
              if (data) {
                this.$next({ name: 'admin.promo-codes' });
              } else {
                this.splitDateAndTime();
              }
            }
            this.loading = false;
          },
        });
      }
    },
    discard() {
      this.$next({ name: 'admin.promo-codes' });
    },
    priceFormat(value) {
      if (value) {
        value = value.toString();
        value = value.split(".")
        return (value.length === 2 && value[1].length > 2) ? false : true
      }
      return true;
    },
    pricePercentageFormat(value) {
      if (value) {
        value = value.toString();
        value = value.split(".")
        return (value.length === 2 && value[1].length > 2) ? false : true
      }
      return true;
    }
  },
};
</script>

<style scoped>
.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
}
</style>
