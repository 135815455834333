<template>
  <div>
    <v-card class="mb-4">
      <v-card-title>
        <div>Filters</div>

        <v-spacer />

        <v-btn icon small @click="hideFilter = !hideFilter">
          <v-icon small color="primary">mdi-chevron-down</v-icon>
        </v-btn>

        <v-btn icon small>
          <v-icon small color="primary" @click="resetFilter()">mdi-reload</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text v-show="!hideFilter" class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              dense
              hide-details
              clearable
              name="name"
              label="Name"
              v-model="query.name"
              placeholder="e.g. PROMO"
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              v-on:keypress="isNumber($event)"
              dense
              hide-details
              clearable
              name="amount"
              label="Amount"
              v-model="query.amount"
              placeholder="e.g. 10"
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              v-on:keypress="isNumber($event)"
              dense
              hide-details
              clearable
              name="users"
              label="Uses"
              v-model="query.uses"
              placeholder="e.g. 10"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              dense
              hide-details
              clearable
              name="status"
              label="Status"
              v-model="query.status"
              placeholder="e.g. 10"
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-menu
              ref="mfilterTransDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              v-model="mfilterTransDate"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <v-text-field
                    outlined
                    hide-details
                    @click:clear="clear('filterTransDate')"
                    dense
                    label="Promocode Date"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="parseTransDateFormat"
                  />
                </div>
              </template>
              <v-date-picker v-model="query.filterTransDate" range  @change="handeleTransDate">
                <v-btn 
                  text color="primary" @click="mfilterTransDate = false">Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn 
                  text color="primary" @click="clearDate()">Clear
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title class="d-flex">
        <v-row>
          <v-col>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on">
                  Action
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(item, index) in actions" @click="selectAction(item)" :key="index">
                  <v-menu offset-x open-on-hover v-if="item.items">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-icon class="mr-2" v-bind="attrs" v-on="on">
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title v-bind="attrs" v-on="on">{{ item.title }}</v-list-item-title>
                    </template>
                    <v-list dense>
                      <v-list-item v-for="(item, index) in item.items" @click="subStatusSelect(item)" :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <template v-else>
                    <v-list-item-icon class="mr-2">
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </template>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>

          <v-spacer />
          <v-col cols="12" sm="4" xl="3" class="text-left text-sm-right">
            <v-row>
              <v-col cols="6">
                <v-btn block outlined color="primary" @click="exportExcel()" :loading="loading.export">Export</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block color="primary" @click="changeRoute()">Add new</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        v-model="selected"
        show-select
        item-key="_id"
        :headers="headers"
        :items="desserts"
        hide-default-footer
      >
        <template v-slot:[`item.startDate`]="{ item }">
          <span>{{ item.startsAt | dateTime }}</span>
        </template>
        <template v-slot:[`item.endDate`]="{ item }">
          <span>{{ item.expiresAt | dateTime }}</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span>{{ item.status | capitalize }}</span>
        </template>
        <template v-slot:[`item.discountedPrice`]="{ item }">
          <span v-if="item.discountedPrice">{{ item.discountedPrice | currency }}</span>
          <span v-if="item.discountedPercentage">{{ item.discountedPercentage }}%</span>
        </template>
        <template v-slot:[`item.remainingRedemptions`]="{ item }">
          <span><a v-if="item.numberOfRedemptions - item.remainingRedemptions > 0" @click="showCount(item)">{{ item.numberOfRedemptions - item.remainingRedemptions }}</a><span v-else>{{ item.numberOfRedemptions - item.remainingRedemptions }}</span>/{{ item.numberOfRedemptions }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" title="Edit" class="mr-1" @click="editItem(item)">
                mdi-pencil-outline
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" title="Delete" class="mr-1" @click="deleteItem(item)">
                mdi-delete-outline
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
        <template v-slot:footer>
          <v-pagination
            v-if="totalPage"
            class="text-right text-body-2 py-2 "
            circle
            :total-visible="5"
            color="primary"
            v-model="query.page"
            :length="totalPage"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import { PromocodeService } from '@services';
export default {
  data() {
    return {
      actions: [
        { title: 'Delete', icon: 'mdi-delete-outline' },
        {
          title: 'Change status',
          icon: 'mdi-find-replace',
          items: [
            { title: 'Draft', key: 'draft' },
            { title: 'Active', key: 'active' },
            { title: 'Inactive', key: 'inactive'}
          ],
        },
      ],
      selectedAction: 0,
      page: 1,
      selected: [],
      hideFilter: false,
      startdatePicker: false,
      enddatePicker: false,
      query: {
        name: '',
        amount: '',
        status: '',
        uses: '',
        startsAt: '',
        endsAt: '',
        page: 1,
        filterTransDate: [],
      },
      totalPage: '',
      headers: [
        { text: 'Actions', value: 'actions', sortable: false, width: '100px' },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'code',
          width: '200px',
        },
        { text: 'Amount', value: 'discountedPrice', sortable: false, width: '100px' },
        { text: 'Uses', value: 'remainingRedemptions', sortable: false, width: '100px' },
        { text: 'Status', value: 'status', sortable: false, width: '100px' },
        { text: 'Start Date', value: 'startDate', sortable: false, width: '150px' },
        { text: 'End Date', value: 'endDate', sortable: false, width: '150px' },
      ],
      desserts: [],
      loading: {
        export: false,
      },
      isValidTransDate: true,
      mfilterTransDate: false,
    };
  },
  computed: {
    parseTransDateFormat() {
      return this.$parseTransDateFormat(this.query.filterTransDate);
    }
  },
  created() {
    Object.assign(this.query, this.$getState());
    this.getPromocode();
  },
  methods: {
    async getPromocode() {
      this.$setState(this.query);
      const data = await PromocodeService.get(this.query);
      if (data) {
        this.desserts = data.promocodes;
        this.totalPage = Number(parseInt((data.totalCount + 10 - 1) / 10));
      }
    },
    resetFilter() {
      this.query.name = '';
      this.query.amount = '';
      this.query.status = '';
      this.query.uses = '';
      this.query.startsAt = '';
      this.query.endsAt = '';
      this.query.page = 1;
      this.getPromocode();
    },
    changeRoute() {
      this.$next({ name: 'promoter.promo-codes.add' });
    },
    editItem(item) {
      this.$next({ name: 'promoter.promo-codes.edit', params: { id: item._id } });
    },
    clear(field) {
      this.query[field] = '';
      this.getPromocode();
    },
    clearDate() {
      this.$refs.mfilterTransDate.save([]);
      this.query['startsAt'] = '';
      this.query['endsAt'] = '';
      this.query.filterTransDate = [];
      this.getPromocode();
    },
    async deleteItem(item) {
      this.$confirm({
        title: `Are you sure you want to delete this promocode?`,
        resolve: async () => {
          await PromocodeService.delete(item._id);
          this.getPromocode();
        },
      });
    },
    async exportExcel() {
      this.loading.export = true;
      const exportdata = await PromocodeService.exportExcel(this.query);
      if (exportdata) {
        const url = URL.createObjectURL(new Blob([exportdata]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Promocode-${moment().format('DD/MM/YYYY')}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
      this.loading.export = false;
    },
    async selectAction(item) {
      if (item.title === 'Delete') {
        await this.updateMany({ action: 'delete' });
      }
    },
    async subStatusSelect(item) {
      await this.updateMany({ action: 'update', status: item.key });
    },
    showCount(item) {
      this.$root.$emit('count-list', item)
    },
    async updateMany(data) {
      if (!this.selected.length) {
        return false;
      }
      const ids = [];
      for (let index = 0; index < this.selected.length; index++) {
        const element = this.selected[index];
        ids.push(element._id);
      }
      const res = await PromocodeService.updateMany({ ids, action: data.action, status: data.status });
      if (res) {
        this.selected = [];
        this.getPromocode();
      }
    },
    handeleTransDate(val) {
      this.isValidTransDate = this.$validateDateRange(val);
    }
  },
  watch: {
    'query.name': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.name && this.query.name.length > 2) {
          this.query.page = 1;
          this.getPromocode();
        }
        if (!this.query.name) {
          this.query.page = 1;
          this.getPromocode();
        }
      }, 600);
    },
    'query.amount': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.amount && this.query.amount.length > 0) {
          this.query.page = 1;
          this.getPromocode();
        }
        if (!this.query.amount) {
          this.query.page = 1;
          this.getPromocode();
        }
      }, 600);
    },
    'query.status': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.status && this.query.status.length > 1) {
          this.query.page = 1;
          this.getPromocode();
        }
        if (!this.query.status) {
          this.query.page = 1;
          this.getPromocode();
        }
      }, 600);
    },
    'query.uses': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.uses && this.query.uses.length > 0) {
          this.query.page = 1;
          this.getPromocode();
        }
        if (!this.query.uses) {
          this.query.page = 1;
          this.getPromocode();
        }
      }, 600);
    },
    'query.page': function() {
      this.getPromocode();
    },
    'query.filterTransDate': function() {
      if (this.query.filterTransDate.length > 1 && this.isValidTransDate) {
        this.query.page = 1;
        this.query.startsAt = this.query.filterTransDate[0];
        this.query.endsAt = this.query.filterTransDate[1];
        this.getPromocode();
      }
    }
  },
};
</script>
